.cell-center {
  text-align: center;
}

.cell-center>* {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}

.cell-right>* {
  margin: 0 0 0 auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.borderBottom {
  padding-bottom: 20px;
  border-bottom: 2px solid #c3bfbf;
}